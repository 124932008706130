import React, { useCallback } from 'react';
import { Button, makeStyles, Text } from '@fluentui/react-components';
import { FILE_STATUS, IDocumentDetails } from '../../../@types/models';
import { Document } from '@si/fib-icons';
import { openLink } from '../../../services/api/dialogService';
import { t } from "i18next";
import { isOutlookApp } from '../../../utils/Helper';

const useStyles = makeStyles({
    ctnButton:
    {
        borderRadius: "4px",
        background: "#0F6CBD",
        color: 'white',
        width: '202px'
    },
    detailBody: {
        minHeight: 'inherit',
        width: '100%',
        justifyContent: "space-between",
        display: 'flex',
        flexDirection: "column"
    },
    containerDetails: {
        minHeight: 'inherit'
    },
    containerHeaderDiv: {
        borderBottom: "1px solid #00000026",
    },
    containerHeaderItemHeading: {
        gap: "8px",
        fontSize: "16px",
        fontWeight: "600",
        padding: "8px",
        display: "flex",

    },
    containerHeaderItemLogo: {
        borderRadius: "100px",
        gap: "10px",
        backgroundColor: "#B5D9D94D",
        justifyContent: 'center',
        width: "42px",
        height: "42px",
        display: "flex"
    },
    containerHeaderLogo: {
        paddingTop: "10px",
        height: "20px",
    },
    containerHeaderItemNumber: {
        fontWeight: "600",
        fontSize: "14px",
        color: "rgba(66, 66, 66, 0.60)",
        fontStyle: "normal",
        lineHeight: "20px"
    },
    containerBodyDiv: {
        gap: "12px",
        display: 'flex',
        flexDirection: 'column',
        marginTop: "12px"
    },
    containerBodyFlex: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerBodyTextHeading:
    {
        padding: "0px, 12px, 0px, 12px",
        fontSize: "16px",
        fontWeight: "600",
        gap: "10px"
    },
    containerBodyTextDetails:
    {
        padding: "0px, 12px, 0px, 12px",
        fontSize: "14px",
        gap: "10px",
        lineHeight: "20px",
        fontWeight: "400s",
        color: "#616161"
    },
    containerButton: {
        display: "flex",
        justifyContent: 'flex-end'
    }

});

const ArchiveDetail: React.FC<IDocumentDetails> = (props: IDocumentDetails) => {
    const styles = useStyles();
    const openText = t('Open document in 360');
    const checkInText = t('Check-in file');
    const undotext = t('Undo check-out');
    const responsible = (props.ResponsibleName ? props.ResponsibleName + " - " : "") + props.OrgUnitName;
    const accessCodeAndParagraph = props.AccessCodeDesc + (props.Paragraph ? " / " + props.Paragraph : "");

    const checkinFile = useCallback(() => {
        // implement checkin file
    }, []);

    const undocheckOut = useCallback(() => {
        // implement undo check-out
    }, []);

    return (
        <div className={styles.detailBody}>
            <div className={styles.containerDetails}>
                <div className={styles.containerHeaderDiv}>
                    <div className={styles.containerHeaderItemHeading}>
                        <div className={styles.containerHeaderItemLogo}>
                            <Document className={styles.containerHeaderLogo} size={16}></Document></div>
                        <div>
                            <div>{props.DocumentTitle}</div>
                            <div className={styles.containerHeaderItemNumber}>
                                {props.ArchiveCode + " : " + props.DocumentNumber}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.containerBodyDiv}>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Case')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{props.CaseNumber + " " + props.CaseDescription}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Document Archive')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{props.ArchiveCode}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Document category')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{props.CategoryCode}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Responsible person')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{responsible}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Access code')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{accessCodeAndParagraph}</Text>
                    </div>
                </div>
            </div>
            <div className={styles.containerButton}>
                <Button className={styles.ctnButton} aria-label={openText} onClick={() => openLink(props.DocumentDetailsLink)}>{openText}</Button>
            </div>

            {!isOutlookApp() && props?.FileStatus === FILE_STATUS.CHECKED_OUT ?
                <div className={styles.containerButton}>
                    <Button className={styles.ctnButton} aria-label={checkInText} onClick={checkinFile} >{checkInText}</Button>
                    <Button className={styles.ctnButton} aria-label={undotext} onClick={undocheckOut} >{undotext} </Button>
                </div>
                : null
            }
        </div>
    );
};

export default ArchiveDetail;
